define("discourse/plugins/discourse-video/discourse/components/modal/discourse-video-upload-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "rsvp", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-i18n", "truth-helpers/helpers/not", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _rsvp, _dButton, _dModal, _ajax, _ajaxError, _dIcon, _i18n, _discourseI18n, _not, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UPCHUNK = window.UpChunk;
  class DiscourseVideoUploadForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "file", [_tracking.tracked], function () {
      return this.args.model?.file || null;
    }))();
    #file = (() => (dt7948.i(this, "file"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "videoDurationMinutes", [_tracking.tracked], function () {
      return null;
    }))();
    #videoDurationMinutes = (() => (dt7948.i(this, "videoDurationMinutes"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "uploadProgress", [_tracking.tracked]))();
    #uploadProgress = (() => (dt7948.i(this, "uploadProgress"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "videoInfo", [_tracking.tracked]))();
    #videoInfo = (() => (dt7948.i(this, "videoInfo"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "uploading", [_tracking.tracked], function () {
      return false;
    }))();
    #uploading = (() => (dt7948.i(this, "uploading"), void 0))();
    afterUploadComplete = (() => this.args.model?.afterUploadComplete || null)();
    get fileSize() {
      return this.humanFilesize(this.file.size);
    }
    get isDurationAllowed() {
      if (this.currentUser.staff || this.videoDurationMinutes === null) {
        return true;
      }
      return this.videoDurationMinutes < this.maxVideoDurationMinutes && this.currentUser.trust_level === 4;
    }
    get maxVideoDurationMinutes() {
      return this.currentUser.trust_level === 4 ? this.siteSettings.discourse_video_max_duration_minutes_leaders : this.siteSettings.discourse_video_max_duration_minutes;
    }
    async fileChanged(event1) {
      const file1 = event1.target.files[0];
      this.file = file1;
      const duration1 = await this.getVideoDuration(file1);
      this.videoDurationMinutes = this.durationMinutes(duration1);
    }
    static #_10 = (() => dt7948.n(this.prototype, "fileChanged", [_object.action]))();
    upload() {
      if (this.isAuthorizedVideo(this.file.name) && this.file.size > 0) {
        this.isDurationAllowed ? this.createVideoObject() : this.dialog.alert(!this.maxVideoDurationMinutes ? _discourseI18n.default.t("discourse_video.post.errors.duration_error") : _discourseI18n.default.t("discourse_video.post.errors.allowed_duration_exceeded", {
          allowed_duration: this.maxVideoDurationMinutes
        }));
      } else {
        this.dialog.alert(_discourseI18n.default.t("discourse_video.post.errors.upload_not_authorized", {
          authorized_extensions: this.videoExtensionsToArray().join(", ")
        }));
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "upload", [_object.action]))();
    async getVideoDuration(file1) {
      return new _rsvp.Promise((resolve1, reject1) => {
        let video1 = document.createElement("video");
        video1.preload = "metadata";
        video1.onloadedmetadata = () => {
          resolve1(video1.duration);
        };
        video1.onerror = () => {
          reject1("Error processing video file");
        };
        video1.src = URL.createObjectURL(file1);
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "getVideoDuration", [_object.action]))();
    async createVideoObject() {
      try {
        this.uploading = true;
        this.setProgress("preparing");
        const videoInfo1 = await (0, _ajax.ajax)("/discourse_video/create", {
          type: "POST",
          data: {
            filename: this.file.name
          }
        });
        this.setupUpChunk(videoInfo1);
      } catch (reason1) {
        this.uploading = false;
        this.setProgress("error");
        (0, _ajaxError.popupAjaxError)(reason1);
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "createVideoObject", [_object.action]))();
    setProgress(key1, args1) {
      this.uploadProgress = _discourseI18n.default.t(`discourse_video.upload_progress.${key1}`, args1);
    }
    setupUpChunk(videoInfo1) {
      this.setProgress("starting");
      this.videoInfo = videoInfo1;
      const upload1 = UPCHUNK.createUpload({
        endpoint: videoInfo1["api_request_url"],
        file: this.file,
        chunkSize: 5120
      });
      upload1.on("progress", progress1 => {
        this.setProgress("uploading", {
          progress: progress1.detail.toFixed(1)
        });
      });
      upload1.on("success", () => {
        this.uploadComplete();
      });
    }
    uploadComplete() {
      const videoInfo1 = this.videoInfo;
      this.setProgress("complete", {
        info: `[video=${videoInfo1["video_id"]}]`
      });
      let videoTag1 = `[video=${videoInfo1["video_id"]}]`;
      if (this.siteSettings.discourse_video_enable_mp4_download === true) {
        videoTag1 += ` [download-video=${videoInfo1["video_id"]}]`;
      }
      if (this.afterUploadComplete) {
        this.afterUploadComplete(videoTag1);
      } else {
        this.appEvents.trigger("composer:insert-text", videoTag1);
      }
      this.args.closeModal();
    }
    videoExtensionsToArray() {
      return this.siteSettings.discourse_video_file_extensions.toLowerCase().replace(/[\s\.]+/g, "").split("|").filter(ext1 => !ext1.includes("*"));
    }
    isAuthorizedVideo(fileName1) {
      return new RegExp(`\\.(${this.videoExtensionsToArray().join("|")})$`, "i").test(fileName1);
    }
    durationMinutes(duration1) {
      return parseInt(duration1 / 60, 10);
    }
    humanFilesize(size1) {
      let i1 = size1 === 0 ? 0 : Math.floor(Math.log(size1) / Math.log(1024));
      return (size1 / Math.pow(1024, i1)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i1];
    }
    static #_14 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n "discourse_video.modal_title"}}
          @subtitle={{i18n "discourse_video.modal_subtitle"}}
          class="discourse-video-upload-modal"
          @closeModal={{@closeModal}}
        >
          <:body>
            <h3>{{i18n "discourse_video.file"}}</h3>
            <p>
              {{#if this.file}}
                {{this.file.name}}
                ({{this.fileSize}})
              {{/if}}
              <label
                class="btn"
                disabled={{this.uploading}}
                title={{i18n "discourse_video.select_file"}}
              >
                {{dIcon "video"}}&nbsp;{{i18n "discourse_video.select_file"}}
                <input
                  disabled={{this.uploading}}
                  type="file"
                  id="video-upload-input"
                  onchange={{this.fileChanged}}
                />
              </label>
            </p>
          </:body>
          <:footer>
            {{#if this.uploading}}
              {{this.uploadProgress}}
            {{else}}
              <DButton
                @action={{this.upload}}
                @icon="upload"
                @label="upload"
                @disabled={{not this.file}}
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "D0Y8VMrd",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"discourse-video-upload-modal\"]],[[\"@title\",\"@subtitle\",\"@closeModal\"],[[28,[32,1],[\"discourse_video.modal_title\"],null],[28,[32,1],[\"discourse_video.modal_subtitle\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[32,1],[\"discourse_video.file\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"file\"]],[[[1,\"            \"],[1,[30,0,[\"file\",\"name\"]]],[1,\"\\n            (\"],[1,[30,0,[\"fileSize\"]]],[1,\")\\n\"]],[]],null],[1,\"          \"],[10,\"label\"],[14,0,\"btn\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[15,\"title\",[28,[32,1],[\"discourse_video.select_file\"],null]],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"video\"],null]],[1,\" \"],[1,[28,[32,1],[\"discourse_video.select_file\"],null]],[1,\"\\n            \"],[10,\"input\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,1,\"video-upload-input\"],[15,\"onchange\",[30,0,[\"fileChanged\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"uploading\"]],[[[1,\"          \"],[1,[30,0,[\"uploadProgress\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],null,[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"upload\"]],\"upload\",\"upload\",[28,[32,4],[[30,0,[\"file\"]]],null]]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-video/discourse/components/modal/discourse-video-upload-form.js",
      "scope": () => [_dModal.default, _i18n.default, _dIcon.default, _dButton.default, _not.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscourseVideoUploadForm;
});